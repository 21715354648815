<template lang="html">
  <div id="footer">
    <div id="footer_container">
      <img id="logo" src="../assets/k3.svg" alt="" />
      <div>
        <p id="footer-text">
          <span id="k3">k3:</span> katharina kreps kreativdesign<br />
          <span id="spacer">im brenntenhau 21<br /></span>
          <span id="spacer">71229 Leonberg</span>
        </p>
      </div>

      <div>
        <p id="contact">
          <span
            ><img src="../assets/mail.svg" /><a href="mailto:k3@kreps-grafik.de"
              >k3@kreps-grafik.de</a
            ></span
          >
          <span>
            <img src="../assets/phone.svg" />
            <a href="tel:+49-160-9706340"> +49 160 97063401 </a>
          </span>
          <span>
            <img src="../assets/instagram-seeklogo.com.svg" />
            <a href="https://www.instagram.com/kreps_grafik/">
              @kreps_grafik
            </a>
          </span>
        </p>
      </div>

      <div>
        <p id="links">
          <a href="impressum">Impressum</a>
          <br />
          <a href="datenschutz">Datenschutz</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../main.scss";

#footer {
  background: $k3-black;

  padding-bottom: 18px;
  padding-top: 18px;
}

#footer_container {
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  #footer-text {
    padding-top: 75px;
    color: #c7c4c4;
    font-family: "PT Sans";
    font-size: 20px;
    #k3 {
      color: #ffffff;
    }
    #spacer {
      margin-left: 30px;
    }
  }

  #contact {
    padding-top: 79px;
    span {
      display: block;
      margin: 2px;
    }
    img {
      margin-right: 20px;
    }
    a {
      color: #c7c4c4;
      font-family: "PT Sans";
      font-size: 20px;
      text-decoration: none;
    }
  }

  #links {
    padding-top: 79px;
    a {
      display: block;
      color: #c7c4c4;
      font-family: "PT Sans";
      font-size: 20px;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 1024px) {
  #footer_container {
    #logo {
      height: 178px;
    }
  }
}
@media screen and (max-width: 768px) {
  #footer-text,
  #contact,
  #links {
    text-align: center;
    padding: 0 !important;

    #spacer {
      margin-left: 0px !important;
    }
  }

  #footer_container {
    flex-direction: column;
  }
}
</style>
