import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("../views/main.vue"),
  },
  {
    path: "/project/:url",
    name: "project",
    component: () => import("../views/project.vue"),
    props: true,
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/about.vue"),
  },
  {
    path: "/olga",
    name: "olga",
    component: () => import("../views/olga.vue"),
  },
  {
    path: "/impressum",
    name: "imprint",
    component: () => import("../views/imprint.vue"),
  },
  {
    path: "/datenschutz",
    name: "privacy",
    component: () => import("../views/privacy.vue"),
  },
  {
    path: "/:url",
    name: "category",
    component: () => import("../views/category.vue"),
    props: true,
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/error/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
