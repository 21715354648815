<template lang="html">
  <div>
    <transition name="MenuTransition">
      <div class="menu" v-if="open">
        <img
          src="../assets/close.svg"
          alt=""
          id="close"
          @click="
            () => {
              this.open = false;
            }
          "
        />
        <div id="links">
          <a href="/print" :class="isActive('print')">Print</a><br />
          <a href="/digital" :class="isActive('digital')">Digital</a><br />
          <a href="/packaging" :class="isActive('packaging')">Packaging</a
          ><br />
          <a href="/film" :class="isActive('film')">Film</a><br />
          <a href="/about" :class="isActive('about')">Über mich</a><br />
          <a href="/olga" :class="isActive('olga')">Über Olga</a><br />
        </div>
      </div>
    </transition>

    <transition name="ContactTransition">
      <div class="contact" v-if="open">
        <div id="links">
          <a href="mailto:k3@kreps-grafik.de"
            ><img src="../assets/mail.svg" alt=""
          /></a>
          <a href="tel:+49-160-9706340"
            ><img src="../assets/phone.svg" alt=""
          /></a>
        </div>
      </div>
    </transition>

    <div class="header">
      <div id="bar">
        <img
          src="../assets/burger.svg"
          alt=""
          id="burger"
          @click="
            () => {
              this.open = true;
            }
          "
        />
        <a href="/"><img alt="" id="logo" /></a>
        <div id="links">
          <a href="/print" :class="isActive('print')">Print</a>
          <a href="/digital" :class="isActive('digital')">Digital</a>
          <a href="/packaging" :class="isActive('packaging')">Packaging</a>
          <a href="/film" :class="isActive('film')">Film</a>
          <a href="/about" :class="isActive('about')">Über mich</a>
          <a href="/olga" :class="isActive('olga')">Über Olga</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    active: String,
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    isActive: function (active) {
      if (window.location.pathname.split("/")[1] == active) {
        return "active";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../main.scss";

.header {
  height: 89px;

  #bar {
    position: fixed;
    height: 89px;
    background-color: white;
    width: 100vw;
    z-index: 10;

    #links {
      display: inline-block;
      margin-top: 35px;
      float: right;
      margin-right: 121px;

      a {
        color: $k3-black;
        font-family: "PT Sans";
        font-size: 20px;
        font-weight: regular;
        text-decoration: none;
        line-height: 70px;
        margin-right: 16px;
      }

      .active {
        text-decoration: underline;
        text-decoration-color: $k3-red;
      }
    }
  }

  #logo {
    max-height: 89px;
    margin-top: 2px;
    margin-right: 12px;
    float: right;
    content: url("../assets/logo_desktop.png");
  }

  #burger {
    border: 2px solid $k3-black;
    cursor: pointer;
    display: none;
  }
}

.menu {
  position: fixed;
  z-index: 12;
  height: 100vh;
  width: 215px;
  background-color: $k3-black;

  #links {
    width: 124px;
    margin-left: 45.5px;
    padding-top: 75px;

    a {
      color: white;
      font-family: "PT Sans";
      font-size: 16px;
      font-weight: regular;
      text-decoration: none;
      line-height: 40px;
    }

    .active {
      text-decoration: underline;
      text-decoration-color: $k3-red;
    }
  }

  #close {
    width: 42.5px;
    height: 42.5px;
    border: 2px solid white;
    object-fit: scale-down;
    cursor: pointer;
  }
}

.contact {
  position: fixed;
  width: 100vw;
  height: 51px;
  background-color: $k3-black;
  z-index: 13;
  bottom: 0px;

  border-top: 1px solid $k3-red;

  #links {
    position: relative;
    display: flex;
    height: 22.5px;
    width: 100px;
    justify-content: space-between;

    left: 50%;
    transform: translatex(-50%);

    margin-top: 14.25px;

    img {
      height: 100%;
    }
  }
}

.MenuTransition-enter-active,
.MenuTransition-leave-active {
  transition: transform 0.5s ease;
}
.MenuTransition-enter,
.MenuTransition-leave-to {
  transform: translatex(-100%) !important;
}
.MenuTransition-enter-to,
.MenuTransition-leave {
  transform: translatex(0px) !important;
}

.ContactTransition-enter-active,
.ContactTransition-leave-active {
  transition: transform 0.5s ease;
}
.ContactTransition-enter,
.ContactTransition-leave-to {
  transform: translatey(100%) !important;
}
.ContactTransition-enter-to,
.ContactTransition-leave {
  transform: translatey(0px) !important;
}

//mobile
@media screen and (max-width: 720px) {
  #bar {
    height: 45px !important;

    #links {
      display: none !important;
    }

    #logo {
      content: url("../assets/logo.png");
      margin-right: -6px;
    }
  }
  #burger {
    display: inline-block !important;
  }
  .header {
    height: 65px !important;
  }
}
</style>
